import { toastr } from 'react-redux-toastr';
import { reset as resetForm, initialize } from 'redux-form';
import axios from 'axios';
import consts from '../consts';

export function initForm(registro = {}) {
    return [
        initialize('authForm', registro)
    ];
}

export function login(values) {
	return submit(values, `${consts.OAPI_URL}/usuario/login`);
}

export function signup(values) {
	return submit({ ...values, id_usuario_inclusao: 1, id_usuario_alteracao: 1 }, `${consts.OAPI_URL}/usuario/cadastrar`);
}

function submit(values, url) {
	return dispatch => {
		axios.post(url, values)
		.then(resp => {
			dispatch([
				{type: 'USER_FETCHED', payload: resp.data}
			]);
		})
		.catch(e => {
			console.log(e);
			if (e.response.status == 400) {
				if (e.response.data instanceof Array) {
					e.response.data.forEach(retorno => {
						dispatch([{
							type: 'USER_MESSEGE',
							payload: {
								open: true,
								text: retorno.msg ? retorno.msg : retorno,
								time: 5000,
								type: 'error'
							}
						}]);
						setTimeout(() => {
							dispatch([{
								type: 'USER_MESSEGE',
								payload: {
									open: false,
									text: '',
									time: 5000,
									type: 'error'
								}
							}]);
						}, 6000);
					});
				} else {
					dispatch([{
						type: 'USER_MESSEGE',
						payload: {
							open: true,
							text: e.response.data,
							time: 5000,
							type: 'error'
						}
					}]);
					setTimeout(() => {
						dispatch([{
							type: 'USER_MESSEGE',
							payload: {
								open: false,
								text: '',
								time: 5000,
								type: 'error'
							}
						}]);
					}, 6000);
				}
			} else {
				dispatch([{
					type: 'USER_MESSEGE',
					payload: {
						open: true,
						text: 'Erro ao realizar Operação!!',
						time: 5000,
						type: 'error'
					}
				}]);
				setTimeout(() => {
					dispatch([{
						type: 'USER_MESSEGE',
						payload: {
							open: false,
							text: '',
							time: 5000,
							type: 'error'
						}
					}]);
				}, 6000);
			}
		});
	}
}

export function logout() {
	return {type: 'TOKEN_VALIDATED', payload: false};
}

export function validateToken(token) {
	return dispatch => {
		if (token) {
			axios.post(`${consts.OAPI_URL}/usuario/validateToken`, {token})
			.then(resp => {
				dispatch({type: 'TOKEN_VALIDATED', payload: resp.data.valid})
			})
			.catch(e => dispatch({type: 'TOKEN_VALIDATED', payload: false}))
		} else {
			dispatch({type: 'TOKEN_VALIDATED', payload: false});
		}
	}
}

export function setCliente(id_cliente) {
	return {
		type: 'USER_CLIENTE_SELECIONADO',
		payload: id_cliente
	};
}

export function carregarUsuario() {
	return (dispatch, getState) => {
		axios.get(`${consts.API_URL}/usuario/carregar?id=${getState().auth.usuario ? getState().auth.usuario.id : 0}`)
		.then(resp => {
			dispatch({
		        type: 'USER_CARREGADO',
		        payload: resp
		    })
		})
		.catch(e => {
			console.log();
		})
	}
}
