import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './produtoForm';
import FormatUtils from '../common/formatUtils/FormatUtils';

import { setModoTela, initForm, salvar, excluir, getLista } from './produtoActions';
import './produto.css';
import ProdutoGerador from './produtoGerador';
import ProdutoConsumidor from './produtoConsumidor';


class Produto extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: ''
	}

    componentWillMount() {
        this.props.getLista();
    }

    render() {

		if (this.props.usuarioCarregado) {
			if (this.props.usuarioCarregado.gerador) {
				return <ProdutoGerador />
			} else if (this.props.usuarioCarregado.consumidor) {
				return <ProdutoConsumidor />
			}
		}
		return (
			<div></div>
		);
    }

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	usuarioCarregado: state.auth.usuarioCarregado,
	modoTela: state.produto.modoTela,
	lista: state.produto.lista.filter(item => item.id_usuario == state.auth.usuario.id)
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Produto);
