import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import AuthReducer from '../auth/authReducer';
import DashboardReducer from '../dashboard/dashboardReducer';
import TabReducer from '../common/tab/tabReducer';
import UsuarioReducer from '../usuario/usuarioReducer';
import ProdutoReducer from '../produto/produtoReducer';

const rootReducer = combineReducers({
	auth: AuthReducer,
	dashboard: DashboardReducer,
	usuario: UsuarioReducer,
	produto: ProdutoReducer,
    tab: TabReducer,
    form: formReducer,
    toastr: toastrReducer
});

export default rootReducer;

