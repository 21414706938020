import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import ContentCardBody from '../common/template/contentCardBody';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import Button from '../common/button/button';
import Form from './produtoForm';
import FormatUtils from '../common/formatUtils/FormatUtils';

import { setModoTela, initForm, salvar, excluir, getLista } from './produtoActions';
import './produto.css';


class ProdutoGerador extends Component {

	state = {
		linhaSelecionada: null,
		pesquisar: ''
	}

    componentWillMount() {
        this.props.getLista();
    }

    render() {
        return (
            <div>
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<>
				<ContentCard>
					<ContentCardHeader>
						<Row>
							<Grid cols='12 12 4 2'>
								<Button
									text='Adicionar Produto'
									type={'primary'}
									icon={'fa fa-plus'}
									event={() => {
										this.props.setModoTela('cadastro', {});
										this.props.initForm({});
									}} />
							</Grid>
						</Row>
					</ContentCardHeader>
				</ContentCard>
				
				<Row>
				{this.props.lista.map(item => {
						return (
							<Grid key={item.id} cols='12 12 6 4'>
								<div className="product-card">
									<img
										src={'https://images.ecycle.com.br/wp-content/uploads/2023/02/17155010/o-ceu-do-por-do-sol-alimenta-a-energia-eolica-e-solar-gerada-pela-ia.jpg.webp'}
										alt={'Nome'}
										className="product-image" />
										
									<div className="product-details">
										<h3 className="product-title">{item.nome}</h3>

										<h3 className="product-descriptions-title">Informações adicionais:</h3>
										<h3 className="product-descriptions">{item.descricao}</h3>

										<h3 className="product-descriptions-title">Unidade Geradora:</h3>
										<h3 className="product-descriptions">{item.nome_usuario}</h3>

										<p className="product-price">R$ {FormatUtils.formatarValorTela(item.preco, 6)}</p>

										<Row alignCenter>
											<span class={`badge badge-${item.liberado ? 'success' : 'danger'}`} style={{ fontSize: 18 }}>{item.liberado ? 'Liberado' : 'Bloqueado'}</span>
										</Row>
											
										<button className="add-to-cart"
											onClick={() => {
												this.props.setModoTela('cadastro', {
													...item,
													cotas: FormatUtils.formatarValorTela(item.cotas, 0),
													preco: FormatUtils.formatarValorTela(item.preco, 6)
												});
												this.props.initForm({
													...item,
													cotas: FormatUtils.formatarValorTela(item.cotas, 0),
													preco: FormatUtils.formatarValorTela(item.preco, 6)
												});
											}}>
											Editar
										</button>
									</div>
								</div>
							</Grid>
						);
					})}
				</Row>
			</>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.produto.modoTela,
	lista: state.produto.lista.filter(item => item.id_usuario == state.auth.usuario.id)
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ProdutoGerador);
